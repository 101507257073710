import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { testsUrlApi } from "../config/api";
import { Test, TestEditResponse } from "@aten/common/dist/models/Test";
import { RootState } from "store";
import { ResponseApi } from "@aten/common/models";
export interface TestResponse extends ResponseApi<Test[] | any> {}
export const testsApi = createApi({
    reducerPath: "testsApi",
    tagTypes: ["Test"],
    baseQuery: fetchBaseQuery({
        baseUrl: testsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getTests: build.query<TestResponse, string>({
            query: () => "",
            providesTags: ["Test"],
        }),
        getTestById: build.query<Test, string>({
            query: (themeId: string) => ({
                url: `/${themeId}`,
            }),
            providesTags: ["Test"],
            transformResponse: (res: any) => res,
        }),
        getTestByThemeId: build.query<TestResponse, string>({
            query: (id: string) => `?themeId=${id}`,
            providesTags: ["Test"],
            transformResponse: (res: TestResponse) => {
                const { objects, ...rest } = res;
                return { ...rest, objects: objects.map((el: Test[]) => ({ ...el, type: "test" })) };
            },
        }),
        setTest: build.mutation<TestEditResponse, Test | undefined>({
            query: (data: Test) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Test"],
        }),
        editTest: build.mutation<TestEditResponse, Test | undefined>({
            query: (data: Test) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Test"],
        }),
        deleteTest: build.mutation<TestResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Test"],
        }),
    }),
});

export const {
    useGetTestsQuery,
    useGetTestByThemeIdQuery,
    useGetTestByIdQuery,
    useEditTestMutation,
    useSetTestMutation,
    useDeleteTestMutation,
} = testsApi;
