import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { usersUrlApi } from "../config/api";
import { User, UserEditResponse, UserRegistration, UserResponse } from "@aten/common/dist/models/User";
import { RootState } from "store";

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl: usersUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUserByToken: builder.query<User, { token: string }>({
      query: ({ token }) => ({
        url: "/ByToken",
        params: {
          token,
        },
      }),
      providesTags: (result, error, arg) => [{ type: "User", phones: result?.phones }],
    }),
    getUserById: builder.query<User | undefined, string>({
      query: (id: string) => ({
        url: "",
        params: {
          UserId: id,
        },
      }),
      providesTags: (result, error, arg) => [{ type: "User", phones: result?.phones }],
      transformResponse: (res: UserResponse) => {
        if (res.objects.length === 0) {
          return undefined;
        }
        return res.objects[0];
      },
    }),
    getUsersByRole: builder.query<User[] | undefined, string>({
      query: (UserRole: string) => ({
        url: "",
        params: {
          UserRole,
        },
      }),
      providesTags: ['User'],
      transformResponse: (res: UserResponse) => {
        if (res.objects.length === 0) {
          return undefined;
        }
        return res.objects;
      },
    }),

    createUser: builder.mutation<string, UserRegistration>({
      query: (body: UserRegistration) => ({
        url: "/Register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation<UserEditResponse, User | undefined>({
      query: (body: User) => ({
        url: `/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useGetUserByTokenQuery, useGetUserByIdQuery, useUpdateUserMutation, useCreateUserMutation, useGetUsersByRoleQuery } = userApi;
