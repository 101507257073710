import { AtenEvent, EventsResponse } from "@aten/common/dist/models/AtenEvent";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { IPagination } from "hooks/usePagination";
import { RootState } from "store";
import { eventsUrlApi } from "../config/api";

export const EventsApi = createApi({
    reducerPath: "EventsApi",
    tagTypes: ["Event"],
    baseQuery: fetchBaseQuery({
        baseUrl: eventsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getEvents: builder.query<EventsResponse, { userId?: number; pagination?: IPagination }>({
            query: ({ userId, pagination }) => {
                return {
                    url: ``,
                    params: {
                        ...pagination,
                        userId,
                    },
                };
            },
            providesTags: ["Event"],
        }),
        getEventById: builder.query<AtenEvent | undefined, string>({
            query: (id: string) => `/${id}`,
            providesTags: ["Event"],
        }),
        newEvent: builder.mutation<string, AtenEvent>({
            query: (body: AtenEvent) => ({
                url: ``,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Event"],
        }),
        deleteEvent: builder.mutation<string, string>({
            query: (id: string) => ({
                url: id.toString(),
                method: "DELETE",
            }),
            invalidatesTags: ["Event"],
        }),
        updateEvent: builder.mutation<string, AtenEvent | undefined>({
            query: (body: AtenEvent) => ({
                url: `/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Event"],
        }),

        getEventMemberExcel: builder.mutation<string, { id: string; name: string }>({
            query: (prop: { id: string; name: string }) => ({
                url: `/DownloadMembersExcel/${prop.id}`,
                responseHandler: async (response: any) =>
                    await response.blob().then((r: any) => {
                        let url = window.URL.createObjectURL(r);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = `${prop.name}.xlsx`;
                        a.click();
                    }),
            }),
        }),
    }),
});

export const {
    useGetEventsQuery,
    useGetEventByIdQuery,
    useDeleteEventMutation,
    useUpdateEventMutation,
    useNewEventMutation,
    useGetEventMemberExcelMutation,
} = EventsApi;
