import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { themesToUsersCourseUrlApi, themesUrlApi } from "../config/api";
import { Theme, ThemeResponse } from "@aten/common/dist/models/Theme";
import { RootState } from "store";

export const themesToUsersApi = createApi({
    reducerPath: "themeToUsersApi",
    tagTypes: ["ThemesToUsers"],
    baseQuery: fetchBaseQuery({
        baseUrl: themesToUsersCourseUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        getThemesToUsersCourse: build.query<
            ThemeResponse,
            { courseId?: string; ObjectsCount?: number; StartIndex?: number; userId?: number }
        >({
            query: ({ courseId, ObjectsCount = 10, StartIndex = 0, userId }) =>
                `?CourseId=${courseId}&ObjectsCount=${ObjectsCount}&StartIndex=${StartIndex}&userId=${userId}`,
            providesTags: ["ThemesToUsers"],
            transformResponse: (data: ThemeResponse) => {
                const { objects, ...rest } = data;
                return { ...rest, objects: objects.map((theme) => ({ ...theme, type: "theme" })) };
            },
        }),
    }),
});

export const { useGetThemesToUsersCourseQuery } = themesToUsersApi;
