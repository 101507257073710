import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { testsStagesUrlApi } from "../config/api";
import { TestEditResponse } from "@aten/common/dist/models/Test";
import { RootState } from "store";
// import { TestNewResponse, testObject, testStage } from "@aten/common/models/Test";
import { ResponseApiWithError } from "@aten/common/models";
import { TestQuestion } from "@aten/common/models/TestQuestion";
import { Image, TestNewResponse } from "@aten/common/models/Test";
// export interface TestResponse extends ResponseApi<testStage[]> {}
export interface Test {
    id: number;
    title: string;
    timeLimit: number;
    imageId: number;
    testId: number;
    userId: number;
    lessonId: number;
    courseId: number;
    evaluationMethodId: number;
    needLessonToPassId: number;
    testQuestions: TestQuestion[];
    themesIds: number[];
    numberOfAttempts: number;
    image?: Image;
    type?: string;
}
export interface TestEdit {
    id?: number;
    userId?: number;
    testId?: number;
    lessonId?: number;
    courseId?: number;
    themeId?: number;
    statusId?: number | string;
    indexNumber?: number;
}
export interface ResponseApi<ObjectType> extends ResponseApiWithError {
    total: number;
    requestedObjectsCount?: number;
    requestedStartIndex?: number;
    objects: ObjectType;
}
export interface TestResponse extends ResponseApi<Test[] | any> {}
export const testsStagesApi = createApi({
    reducerPath: "testsStagesApi",
    tagTypes: ["TestsStages"],
    baseQuery: fetchBaseQuery({
        baseUrl: testsStagesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getTestsStages: build.query<TestResponse, string>({
            query: () => "",
            providesTags: ["TestsStages"],
        }),
        getTestStagesById: build.query<TestResponse, string>({
            query: (themeId: string) => ({
                url: `/${themeId}`,
            }),
            providesTags: ["TestsStages"],
            transformResponse: (res: TestResponse) => res.objects[0],
        }),
        getTestStagesByThemeId: build.query<TestResponse, string>({
            query: (id: string) => `?themeId=${id}`,
            providesTags: ["TestsStages"],
            transformResponse: (res: TestResponse) => {
                const { objects, ...rest } = res;
                return { ...rest, objects: objects.map((el: Test[]) => ({ ...el, type: "test" })) };
            },
        }),
        getTestStagesBy: build.query<TestNewResponse, { courseId?: string; themeId?: string }>({
            query: ({ courseId, themeId }) => `?ThemeId=${themeId}&CourseId=${courseId}`,
            providesTags: ["TestsStages"],
            transformResponse: (data: TestResponse) => {
                const { objects, ...rest } = data;

                return { ...rest, objects: objects.map((theme: Test[]) => ({ ...theme, type: "test" })) };
            },
        }),
        setTestStages: build.mutation<TestEditResponse, Test | undefined>({
            query: (data: Test) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TestsStages"],
        }),
        editTestStages: build.mutation<TestEditResponse, TestEdit | undefined>({
            query: (data: Test) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["TestsStages"],
        }),
        deleteTestStages: build.mutation<TestResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TestsStages"],
        }),
    }),
});

export const {
    useGetTestsStagesQuery,
    useGetTestStagesByThemeIdQuery,
    useGetTestStagesByQuery,
    useGetTestStagesByIdQuery,
    useEditTestStagesMutation,
    useSetTestStagesMutation,
    useDeleteTestStagesMutation,
} = testsStagesApi;
