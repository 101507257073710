import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { themesUrlApi } from "../config/api";
import { Theme, ThemeResponse } from "@aten/common/dist/models/Theme";
import { RootState } from "store";

export const themesApi = createApi({
    reducerPath: "themeApi",
    tagTypes: ["Theme"],
    baseQuery: fetchBaseQuery({
        baseUrl: themesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        getThemes: build.query<ThemeResponse, { courseId?: string; ObjectsCount?: number; StartIndex?: number }>({
            query: ({ courseId, ObjectsCount = 10, StartIndex = 0 }) =>
                `?CourseId=${courseId}&ObjectsCount=${ObjectsCount}&StartIndex=${StartIndex}`,
            providesTags: ["Theme"],
            transformResponse: (data: ThemeResponse) => {
                const { objects, ...rest } = data;
                return { ...rest, objects: objects.map((theme) => ({ ...theme, type: "theme" })) };
            },
        }),

        getThemeById: build.query<Theme, string | undefined>({
            query: (themeId?: string) => ({
                url: `/${themeId}`,
            }),
            providesTags: ["Theme"],
            transformResponse: (data: ThemeResponse) => {
                return data.objects[0];
            },
        }),
    }),
});

export const { useGetThemesQuery, useGetThemeByIdQuery } = themesApi;
