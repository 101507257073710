import { File, FileResponse } from "@aten/common/dist/models/File";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { filesUrlApi } from "config/api";
import { RootState } from "store";

export const filesApi = createApi({
    reducerPath: "filesApi",
    tagTypes: ["File"],

    baseQuery: fetchBaseQuery({
        baseUrl: filesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        getFileById: build.query<File, string>({
            query: (id: string) => `/${id}`,
            transformResponse: (res: FileResponse) => {
                return res.objects;
            },
            providesTags: ["File"],
        }),
        getFileLinkByFileId: build.query<File, string>({
            query: (id: string) => `/${id}`,
            transformResponse: (res: File) => {
                return res;
            },
            providesTags: ["File"],
        }),
        setFile: build.mutation<string, Blob>({
            query(body: Blob) {
                const formDate = new FormData();
                formDate.append("uploadedFile", body);
                return {
                    url: "",
                    method: "POST",
                    credentials: "include",
                    body,
                };
            },
        }),
    }),
});

export const { useGetFileByIdQuery, useGetFileLinkByFileIdQuery, useSetFileMutation } = filesApi;
