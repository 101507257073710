import { ComponentType, lazy } from "react";

type TRoutes<PathType> = {
    [key in keyof PathType]: {
        path: string;
        element: ComponentType;
    };
};

type TRoutesAuthorized = TRoutes<typeof PathAuthorized>;

// type TRoutesPublic = TRoutes<typeof PathPublic>;

export enum PathAuthorized {
    Main = "/",

    News = "/news",
    NewsDetail = "/news/:title",

    Contacts = "/contacts",

    PrivateOffice = "/privateOffice/:key",

    MyOfflineEventDetail = "/privateOffice/:page/offline/:id/detail",
    OfflineEventDetail = "/offline/:id/detail",

    Courses = "/courses/",
    CourseDetail = "/courses/:courseId/detail",

    MyOnlineCourseDetail = "/privateOffice/course/:courseId/detail",
    MyOnlineCourseThemeDetail = "/privateOffice/course/:courseId/theme/:themeId/detail",

    Lesson = "/privateOffice/course/:courseId/theme/:themeId/lesson/:lessonId/detail",
    Test = "/privateOffice/course/:courseId/theme/:themeId/test/:testId/detail",
    TestProcess = "/privateOffice/course/:courseId/theme/:themeId/test/:testId/testQuestion/:testQuestion/process/",
    TestFinal = "/privateOffice/course/:courseId/theme/:themeId/test/:testId/final",
    TestReview = "/privateOffice/course/:courseId/theme/:themeId/test/:testId/review",

    Certificates = "/privateOffice/certificates",
    Prices = "/privateOffice/prices",

    Projects = "/projects",
    ProjectEdit = "/projects/:id/projectEdit",
    ProjectInfo = "/projects/:id/projectInfo",
    ProjectReg = "/projects/projectReg",

    About = "/about",

    Cooperation = "/cooperation",

    NotFound = "*",
}

export const routesAuthorized: TRoutesAuthorized = {
    Main: {
        path: PathAuthorized.Main,
        element: lazy(() => import("pages/Main/Main")),
    },
    News: {
        path: PathAuthorized.News,
        element: lazy(() => import("pages/News/News")),
    },
    NewsDetail: {
        path: PathAuthorized.NewsDetail,
        element: lazy(() => import("pages/NewsDetail/NewsDetail")),
    },
    Contacts: {
        path: PathAuthorized.Contacts,
        element: lazy(() => import("pages/Contacts/Contacts")),
    },
    PrivateOffice: {
        path: PathAuthorized.PrivateOffice,
        element: lazy(() => import("pages/PrivateOffice/PrivateOffice")),
    },
    MyOfflineEventDetail: {
        path: PathAuthorized.MyOfflineEventDetail,
        element: lazy(() => import("pages/PrivateOffice/MyOfflineEvents/MyOfflineEventDetail/MyOfflineEventDetail")),
    },
    OfflineEventDetail: {
        path: PathAuthorized.OfflineEventDetail,
        element: lazy(() => import("pages/PrivateOffice/MyOfflineEvents/MyOfflineEventDetail/MyOfflineEventDetail")),
    },
    Courses: {
        path: PathAuthorized.Courses,
        element: lazy(() => import("pages/Courses/Courses")),
    },
    CourseDetail: {
        path: PathAuthorized.CourseDetail,
        element: lazy(() => import("pages/Courses/CourseDetail/CourseDetail")),
    },
    MyOnlineCourseDetail: {
        path: PathAuthorized.MyOnlineCourseDetail,
        element: lazy(() => import("pages/PrivateOffice/MyOnlineCourses/MyOnlineCourseDetail/MyOnlineCourseDetail")),
    },
    MyOnlineCourseThemeDetail: {
        path: PathAuthorized.MyOnlineCourseThemeDetail,
        element: lazy(
            () => import("pages/PrivateOffice/MyOnlineCourses/MyOnlineCourseThemeDetail/MyOnlineCourseThemeDetail")
        ),
    },
    Lesson: {
        path: PathAuthorized.Lesson,
        element: lazy(() => import("pages/Lesson/Lesson")),
    },
    Test: {
        path: PathAuthorized.Test,
        element: lazy(() => import("pages/Test/Test")),
    },
    TestProcess: {
        path: PathAuthorized.TestProcess,
        element: lazy(() => import("pages/Test/TestProcess/TestProcess")),
    },
    TestFinal: {
        path: PathAuthorized.TestFinal,
        element: lazy(() => import("pages/Test/TestFinal/TestFinal")),
    },
    TestReview: {
        path: PathAuthorized.TestReview,
        element: lazy(() => import("pages/Test/TestReview/TestReview")),
    },
    Certificates: {
        path: PathAuthorized.Certificates,
        element: lazy(() => import("pages/Certificates/Certificates")),
    },
    Prices: {
        path: PathAuthorized.Prices,
        element: lazy(() => import("pages/Prices/Prices")),
    },
    Projects: {
        path: PathAuthorized.Projects,
        element: lazy(() => import("pages/Projects/Projects")),
    },
    ProjectEdit: {
        path: PathAuthorized.ProjectEdit,
        element: lazy(() => import("pages/PrivateOffice/MyProjects/MyProjectOne")),
    },
    ProjectInfo: {
        path: PathAuthorized.ProjectInfo,
        element: lazy(() => import("pages/PrivateOffice/MyProjects/MyProjectOne")),
    },
    ProjectReg: {
        path: PathAuthorized.ProjectReg,
        element: lazy(() => import("pages/PrivateOffice/MyProjects/MyProjectOne")),
    },
    About: {
        path: PathAuthorized.About,
        element: lazy(() => import("pages/About/About")),
    },
    Cooperation: {
        path: PathAuthorized.Cooperation,
        element: lazy(() => import("pages/Cooperation/Cooperation")),
    },
    NotFound: {
        path: PathAuthorized.NotFound,
        element: lazy(() => import("pages/NotFound/NotFound")),
    },
};

// export const routesPublic: TRoutesPublic = {
//   Login: {
//     path: PathPublic.Login,
//     element: lazy(() => import("pages/Login/Login")),
//   },
//   All: {
//     path: PathPublic.All,
//     element: lazy(() => import("pages/Login/Redirect")),
//   },
// };
