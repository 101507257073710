import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionAnswer } from "@aten/common/models/QuestionAnswers";

export interface ITimerSlice {
    endDate: any;
    seconds: number;
    pauseDate?: any;
}

const initialState: ITimerSlice = {
    endDate: 0,
    seconds: 0,
    pauseDate: 0,
};
export const timerSlice = createSlice({
    name: "timer",
    initialState,
    reducers: {
        setTimerValue: (state: any, action: PayloadAction<ITimerSlice>) => {
            state.seconds = action.payload.seconds;
            state.endDate = action.payload.endDate;
            state.pauseDate = action.payload.pauseDate;
        },
    },
});
export const { setTimerValue } = timerSlice.actions;
export default timerSlice.reducer;
