import { EventRegistrationResponse } from "@aten/common/dist/models/eventsRegistration";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { eventRegistrationsUrlApi } from "config/api";
import { RootState } from "store";

export const eventRegistrationsApi = createApi({
    reducerPath: "eventRegistrationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: eventRegistrationsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["Registred"],
    endpoints: (build) => ({
        getIsUsersInEvent: build.query<boolean, { EventId: string; userId: string }>({
            query: ({ EventId, userId }) => ({
                url: "",
                params: {
                    EventId,
                    userId,
                },
            }),
            transformResponse: (res: EventRegistrationResponse) => {
                return !!res.objects.length;
            },
            providesTags: ["Registred"],
        }),
        getEventByUser: build.query({
            query: (userId: number) => ({
                url: "",
            }),
        }),
        setMembersToEvent: build.mutation<{ addedId: number }, any>({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Registred"],
        }),
    }),
});
export const { useGetIsUsersInEventQuery, useGetEventByUserQuery, useSetMembersToEventMutation } =
    eventRegistrationsApi;
