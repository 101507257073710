import { LoadingOutlined } from "@ant-design/icons";
import { FC } from "react";
import concatClasses from "utils/concatClasses";
import styles from "./Loader.module.scss";

interface ILoader {
    small?: boolean;
}

const Loader: FC<ILoader> = ({ small = false }) => (
    <div className={concatClasses("loader", small ? styles.small : "")}>
        <LoadingOutlined spin />
    </div>
);

export default Loader;
