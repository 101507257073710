import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { coursesUrlApi } from "../config/api";
import { Course, CourseResponse } from "@aten/common/dist/models/Course";
import { RootState } from "store";
import { IPagination } from "hooks/usePagination";

export const coursesApi = createApi({
    reducerPath: "coursesApi",
    tagTypes: ["Course"],
    baseQuery: fetchBaseQuery({
        baseUrl: coursesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getCourses: build.query<CourseResponse, { userId?: number; pagination?: IPagination }>({
            query: ({ userId, pagination }) => ({
                url: "",
                params: {
                    userId,
                    // CourseStatus === VerifiedBy
                    CourseStatus: 2,
                    ...pagination,
                },
            }),
            providesTags: ["Course"],
        }),
        getCoursesByIds: build.query<CourseResponse, { ids: string; ObjectsCount?: number; StartIndex?: number }>({
            query: ({ ids, ObjectsCount = 10, StartIndex = 0 }) =>
                `?ListOfIdsInStringFormat=${ids}`,
            providesTags: ["Course"],
        }),
        getCoursesByUserId: build.query<Course, string>({
            query: (id: string) => `/${id}`,
            providesTags: ["Course"],
        }),
        getCourseById: build.query<Course, string>({
            query: (id: string) => `/${id}`,
            providesTags: ["Course"],
        }),
        editCourse: build.mutation<CourseResponse, Course>({
            query: (body: Course) => ({
                url: `${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Course"],
        }),
        newCourse: build.mutation<{ addedId: number }, Course>({
            query: (body: Course) => ({
                url: "",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Course"],
        }),
        deleteCourse: build.mutation<CourseResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Course"],
        }),
    }),
});

export const {
    useGetCoursesQuery,
    useGetCoursesByIdsQuery,
    useGetCourseByIdQuery,
    useGetCoursesByUserIdQuery,
    useEditCourseMutation,
    useNewCourseMutation,
    useDeleteCourseMutation,
} = coursesApi;
