import { EditLessonStage, LessonStage, LessonStagesResponse } from "@aten/common/models/LessonStage";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { lessonStagesUrlApi, lessonsUrlApi } from "../config/api";
import { RootState } from "store";

export const lessonsStagesApi = createApi({
    reducerPath: "lessonsStagesApi",
    tagTypes: ["LessonStages"],
    baseQuery: fetchBaseQuery({
        baseUrl: lessonStagesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getLessonsStages: build.query<LessonStagesResponse, string>({
            query: () => "",
            providesTags: ["LessonStages"],
        }),
        getLessonStagesById: build.query<LessonStage, string>({
            query: (id: string) => `/${id}`,
            transformResponse: (res: LessonStage) => res,
            providesTags: ["LessonStages"],
        }),
        getLessonStagesByThemeId: build.query<LessonStagesResponse, { themeId: string | undefined; courseId?: string }>(
            {
                query: ({ themeId, courseId }) => ({
                    url: "",
                    params: {
                        themeId,
                        courseId,
                    },
                }),
                providesTags: ["LessonStages"],
                transformResponse: (baseQueryReturnValue: LessonStagesResponse) => {
                    const { objects, ...rest } = baseQueryReturnValue;
                    return { ...rest, objects: objects.map((e) => ({ ...e, type: "lesson" })) };
                },
            }
        ),
        getLessonStagesByThemeIdFormat: build.query<LessonStage, string>({
            query: (id: string) => `/${id}`,
            transformResponse: (res: LessonStagesResponse) => res.objects[0],
            providesTags: ["LessonStages"],
        }),
        editLessonStages: build.mutation<string, EditLessonStage>({
            query: (data: LessonStage) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["LessonStages"],
        }),
        setLessonStages: build.mutation<string, LessonStage>({
            query: (data: LessonStage) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["LessonStages"],
        }),
        deleteLessonStages: build.mutation<LessonStagesResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["LessonStages"],
        }),
    }),
});

export const {
    useGetLessonsStagesQuery,
    useGetLessonStagesByThemeIdQuery,
    useGetLessonStagesByIdQuery,
    useEditLessonStagesMutation,
    useSetLessonStagesMutation,
    useDeleteLessonStagesMutation,
    useGetLessonStagesByThemeIdFormatQuery,
} = lessonsStagesApi;
