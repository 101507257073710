import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { questionUrlApi } from "../config/api";
import { TestQuestion, TestQuestionEditResponse, TestQuestionResponse } from "@aten/common/dist/models/TestQuestion";
import { RootState } from "store";

export const questionApi = createApi({
    reducerPath: "questionApi",
    tagTypes: ["TestQuestion"],
    baseQuery: fetchBaseQuery({
        baseUrl: questionUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getTestQuestions: build.query<TestQuestionResponse, string>({
            query: () => "",
            providesTags: ["TestQuestion"],
        }),
        getTestQuestionById: build.query<TestQuestion, string>({
            query: (themeId: string) => ({
                url: `/${themeId}`,
                method: "GET",
            }),
            providesTags: ["TestQuestion"],

            transformResponse: (res: any) => {
                return res;
            },
        }),
        getTestQuestionByTestId: build.query<TestQuestionResponse, string>({
            query: (id: string) => `/${id}`,
            providesTags: ["TestQuestion"],
            //   transformResponse: (res: TestQuestionResponse) => res.objects[0],
        }),
        setTestQuestion: build.mutation<TestQuestionEditResponse, TestQuestion | undefined>({
            query: (data: TestQuestion) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TestQuestion"],
        }),
        editTestQuestion: build.mutation<TestQuestionEditResponse, TestQuestion | undefined>({
            query: (data: TestQuestion) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["TestQuestion"],
        }),
        deleteTestQuestion: build.mutation<TestQuestionResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TestQuestion"],
        }),
    }),
});

export const {
    useGetTestQuestionsQuery,
    useGetTestQuestionByTestIdQuery,
    useGetTestQuestionByIdQuery,
    useEditTestQuestionMutation,
    useSetTestQuestionMutation,
    useDeleteTestQuestionMutation,
} = questionApi;
