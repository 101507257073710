import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PayloadCourse[] = [];

interface PayloadCourse {
    title: string;
    id: number;
}
export const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        setList: (state, action: PayloadAction<PayloadCourse[]>) => {
            return [...action.payload];
        },
    },
});

export default coursesSlice.reducer;
export const { setList } = coursesSlice.actions;
