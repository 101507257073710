import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { testResultsUrlApi } from "../config/api";
import { RootState } from "store";
import { IPutTestResults, ITestResults } from "@aten/common/models/TestResult";
import { TestNewGetResponse, TestNewResponse, TestResuliInfo, TestResultResponse } from "@aten/common/models/Test";
import { IQuestionPayload } from "@aten/common/models/QuestionAnswers";
import { Test, TestResponse } from "./testsStagesService";

export const testsResultApi = createApi({
    reducerPath: "testsResultApi",
    tagTypes: ["TestsResults"],
    baseQuery: fetchBaseQuery({
        baseUrl: testResultsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        setTestResults: build.mutation<TestResultResponse, ITestResults>({
            query: (data: ITestResults) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TestsResults"],
        }),
        putTestResults: build.mutation<TestResultResponse, { data: IPutTestResults; id: number }>({
            query: (arg) => ({
                url: `/${arg.id}`,
                method: "PUT",
                body: arg.data,
            }),
            invalidatesTags: ["TestsResults"],
        }),
        deleteTestResults: build.mutation<TestResultResponse, number>({
            query: (data: number) => ({
                url: `/${data}`,
                method: "DELETE",
                // body: data,
            }),
            invalidatesTags: ["TestsResults"],
        }),
        getTestResultWithInfo: build.query<TestResuliInfo, number>({
            query: (id: number) => ({
                url: `/GetTestResultWithInfo?id=${id}`,
                method: "GET",
            }),
            providesTags: ["TestsResults"],

            transformResponse: (res: any) => {
                return res;
            },
        }),
        getTestResultBy: build.query<TestNewGetResponse, { courseId?: string; themeId?: string; testId?: string }>({
            query: ({ courseId, themeId, testId }) => `?ThemeId=${themeId}&CourseId=${courseId}&TestId=${testId}`,
            providesTags: ["TestsResults"],
            transformResponse: (data: TestResponse) => {
                const { objects, ...rest } = data;

                return { ...rest, objects: objects.map((theme: Test[]) => ({ ...theme, type: "test" })) };
            },
        }),
    }),
});

export const {
    useGetTestResultWithInfoQuery,
    useSetTestResultsMutation,
    usePutTestResultsMutation,
    useDeleteTestResultsMutation,
    useGetTestResultByQuery,
} = testsResultApi;
