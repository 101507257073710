import { PriceListRequest } from "@aten/common/models/PriceList";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { priceUrlLists } from "config/api";
import { RootState } from "store";

export const priceListsApi = createApi({
    reducerPath: "priceListsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: priceUrlLists,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["PriceLists"],
    endpoints: (builder) => ({
        getPriceList: builder.query<PriceListRequest, {}>({
            query: ({ ...params }) => {
                return {
                    url: ``,
                };
            },
            providesTags: ["PriceLists"],
        }),
    }),
});

export const { useGetPriceListQuery } = priceListsApi;
