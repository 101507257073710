import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {managersUrlApi} from "../config/api";
import {Manager, ManagerResponse} from "@aten/common/dist/models/Manager";

import {RootState} from "store";

export const managersApi = createApi({
    reducerPath: "managersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: managersUrlApi,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["Managers"],

    endpoints: (builder) => ({
        getManagers: builder.query<ManagerResponse, number>({
            query: (ObjectsCount: number) => {
                return {
                    url: "",
                    params: {
                        ObjectsCount,
                    },
                };
            },
        }),
        getManagerById: builder.query<Manager, string>({
            query: (id) => {
                return {
                    url: "",
                    params: {
                        Id: id,
                    },
                }
            },
            transformResponse: (res: Manager[]) => {
                return res[0];
            },
            providesTags: ['Managers']
        }),
        setNewManager: builder.mutation<{ addedId: number }, Manager>({
            query: (body) => ({
                url: ``,
                method: "POST",
                body
            })
        })
    }),
});

export const {useGetManagersQuery, useGetManagerByIdQuery, useSetNewManagerMutation} = managersApi;
