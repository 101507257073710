import { LicencesResponse } from "@aten/common/dist/models/Licences";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { licencesUrlApi } from "config/api";
import { IPagination } from "hooks/usePagination";
import { RootState } from "store";

export const licencesApi = createApi({
    reducerPath: "licencesApi",
    tagTypes: ["Licences"],
    baseQuery: fetchBaseQuery({
        baseUrl: licencesUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getLicences: build.query<LicencesResponse, { userId?: number; pagination?: IPagination }>({
            query: ({ userId, pagination }) => {
                return {
                    url: ``,
                    params: {
                        ...pagination,
                        userId,
                    },
                };
            },
            providesTags: ["Licences"],
        }),
    }),
});

export const { useGetLicencesQuery } = licencesApi;
