import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { questionAnswersUrlApi } from "../config/api";
import {
  QuestionAnswer,
  QuestionAnswerResponse,
} from "@aten/common/dist/models/QuestionAnswers";
import { RootState } from "store";

export const questionAnswersApi = createApi({
  reducerPath: "questionAnswersApi",
  tagTypes: ["QuestionAnswer"],
  baseQuery: fetchBaseQuery({
    baseUrl: questionAnswersUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getQuestionAnswers: build.query<QuestionAnswerResponse, string>({
      query: () => "",
      providesTags: ["QuestionAnswer"],
    }),
    getQuestionAnswerById: build.query<QuestionAnswer, string>({
      query: (themeId: string) => ({
        url: `/${themeId}`,
      }),
      providesTags: ["QuestionAnswer"],
      transformResponse: (res: QuestionAnswerResponse) => res.objects[0],
    }),
    getQuestionAnswerByThemeId: build.query<QuestionAnswerResponse, string>({
      query: (id: string) => `/${id}`,
      providesTags: ["QuestionAnswer"],
    }),
    setQuestionAnswer: build.mutation<string, QuestionAnswer>({
      query: (data: QuestionAnswer) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["QuestionAnswer"],
    }),
    editQuestionAnswer: build.mutation<string, QuestionAnswer>({
      query: (data: QuestionAnswer) => ({
        url: `/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["QuestionAnswer"],
    }),
    deleteQuestionAnswer: build.mutation<QuestionAnswerResponse, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["QuestionAnswer"],
    }),
  }),
});

export const {
  useGetQuestionAnswersQuery,
  useGetQuestionAnswerByThemeIdQuery,
  useGetQuestionAnswerByIdQuery,
  useEditQuestionAnswerMutation,
  useSetQuestionAnswerMutation,
  useDeleteQuestionAnswerMutation,
} = questionAnswersApi;
