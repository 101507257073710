import { Engineers } from "@aten/common/dist/models/Engineers";
import { ProjectToEngineersResponse } from "@aten/common/dist/models/projectsToEngineers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { engineersToUsersUrlApi } from "config/api";
import { engineersApi } from "services/engineersService";

import { RootState } from "store";

export const engineersToUsersApi = createApi({
    reducerPath: "engineersToUsersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: engineersToUsersUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        getEngineersToUsers: build.query<ProjectToEngineersResponse, string>({
            query: (UserId: string) => ({
                url: "",
                params: {
                    UserId,
                },
            }),
        }),
        setEngineerToUser: build.mutation<void, { userId: number; engineerId: number }>({
            query: ({ userId, engineerId }) => ({
                url: "",
                method: "POST",
                body: {
                    userId,
                    engineerId,
                },
            }),
        }),
    }),
});

export const engineersToUsersExtendedApi = engineersToUsersApi.injectEndpoints({
    endpoints: (build) => ({
        getEngineersToUsersWithData: build.query<Engineers[], string>({
            async queryFn(id, { dispatch }) {
                const engineersList = await dispatch(
                    engineersToUsersApi.endpoints.getEngineersToUsers.initiate(id, { forceRefetch: true })
                ).unwrap();
                const engineersDataList = await Promise.all(
                    engineersList.objects?.map(
                        ({ engineerId }) =>
                            dispatch(
                                engineersApi.endpoints.getEngineerById.initiate(engineerId.toString(), {
                                    forceRefetch: true,
                                })
                            ).unwrap() ?? []
                    )
                );
                return { data: engineersDataList };
            },
        }),
    }),
});

export const { useGetEngineersToUsersQuery, useGetEngineersToUsersWithDataQuery, useSetEngineerToUserMutation } =
    engineersToUsersExtendedApi;
