export const versionApi = process.env.REACT_APP_API_VERSION;
export const urlApi = `${process.env.REACT_APP_API_SERVER}/${versionApi}`;
export const daDataApiKey = `${process.env.REACT_APP_DADATA_API_KEY}`;

export const usersUrlApi = `${urlApi}/Users`;
export const projectsUrlApi = `${urlApi}/Projects`;
export const managersUrlApi = `${urlApi}/Managers`;
export const engineersUrlApi = `${urlApi}/Engineers`;
export const roleToBudgetUrlApi = `${urlApi}/RoleToBudget`;
export const modelsAndProjectContollerUrlApi = `${urlApi}/ModelsAndProjectContoller`;

export const themesUrlApi = `${urlApi}/Themes`;
export const testsUrlApi = `${urlApi}/Tests`;
export const testResultsUrlApi = `${urlApi}/TestResults`;
export const testAtemptUrlApi = `${urlApi}/AttemptQuestions`;
export const testsStagesUrlApi = `${urlApi}/TestStages`;
export const coursesUrlApi = `${urlApi}/Courses`;

export const filesUrlApi = `${urlApi}/Files`;
export const phonesUrlApi = `${urlApi}/Phones`;
export const eventContactsUrlApi = `${urlApi}/EventContacts`;
export const eventsUrlApi = `${urlApi}/Events`;
export const lessonsUrlApi = `${urlApi}/Lessons`;
export const lessonStagesUrlApi = `${urlApi}/LessonStages`;
export const licencesUrlApi = `${urlApi}/Licences`;
export const userCoursesUrlApi = `${urlApi}/CoursesToUsers`;
export const eventRegistrationsUrlApi = `${urlApi}/EventRegistrations`;
export const themesToUsersCourseUrlApi = `${urlApi}/ThemesToUsersCourse`;
export const coursesToUsersUrlApi = `${urlApi}/CoursesToUsers`;
export const questionUrlApi = `${urlApi}/TestQuestions`;
export const questionAnswersUrlApi = `${urlApi}/QuestionAnswers`;
export const newsUrlApi = `${urlApi}/News`;
export const priceUrlLists = `${urlApi}/PriceLists`;

export const membersUrlApi = `${urlApi}/Members`;
export const advantagesUrlApi = `${urlApi}/Advantages`;
export const managersToUsersUrlApi = `${urlApi}/ManagersToUsers`;
export const managersToProjectUrlApi = `${urlApi}/ProjectsToManagers`;
export const engineersToUsersUrlApi = `${urlApi}/EngineersToUsers`;
export const engineersToProjectUrlApi = `${urlApi}/ProjectsToEngineers`;
export const cooperationUrlApi = `${urlApi}/Cooperation`;
export const eventRegistrationGuestUrlApi = `${urlApi}/EventRegistrationGuest`;
