import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionAnswer } from "@aten/common/models/QuestionAnswers";

const initialState: QuestionAnswer[] = [];
export const testResultSlice = createSlice({
    name: "attempt",
    initialState,
    reducers: {
        setResult: (state: any, action: PayloadAction<QuestionAnswer>) => {
            state = state.push(action.payload);
        },
    },
});
export const { setResult } = testResultSlice.actions;
export default testResultSlice.reducer;
