import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { eventRegistrationGuestUrlApi } from "config/api";
import { RootState } from "store";

export const eventRegistrationGuestApi = createApi({
    reducerPath: "eventRegistrationGuestUrlApi",
    baseQuery: fetchBaseQuery({
        baseUrl: eventRegistrationGuestUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["Registred"],
    endpoints: (build) => ({
        setGuests: build.mutation({
            query: (body) => {
                return {
                    url: "/CreateFew",
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const { useSetGuestsMutation } = eventRegistrationGuestApi;
