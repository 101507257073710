import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@aten/common/dist/models/User";

interface AuthState {
  user: User | undefined;
  token: string;
  isAuth: boolean;
}

interface PayloadUser {
  user: User;
  token: string;
}

const initialState: AuthState = {
  user: undefined,
  token: "",
  isAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<PayloadUser>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuth = true;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isAuth = true;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.user = undefined;
      state.token = "";
      state.isAuth = false;
    },
  },
});

export const { login, logout, setToken, setUser } = authSlice.actions;
export default authSlice.reducer;
