import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { advantagesUrlApi } from "../config/api";
import {
  Advantages,
  AdvantagesResponse,
} from "@aten/common/dist/models/Advantages";
import { RootState } from "store";

export const advantagesApi = createApi({
  reducerPath: "advantagesApi",
  tagTypes: ["Advantages"],
  baseQuery: fetchBaseQuery({
    baseUrl: advantagesUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getAdvantages: build.query<AdvantagesResponse, void>({
      query: () => ({
        url: "",
      }),
      providesTags: ["Advantages"],
    }),
    getAdvantagesById: build.query<Advantages, string>({
      query: (id: string) => `/${id}`,
      transformResponse: (res: AdvantagesResponse) => {
        return res.objects[0];
      },
      providesTags: ["Advantages"],
    }),
    editAdvantages: build.mutation<string, Advantages>({
      query: (body: Advantages) => ({
        url: `${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Advantages"],
    }),
    setAdvantages: build.mutation<string, Advantages>({
      query: (body: Advantages) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Advantages"],
    }),
    deleteAdvantages: build.mutation<AdvantagesResponse, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Advantages"],
    }),
  }),
});

export const {
  useGetAdvantagesQuery,
  useGetAdvantagesByIdQuery,
  useEditAdvantagesMutation,
  useSetAdvantagesMutation,
  useDeleteAdvantagesMutation,
} = advantagesApi;
