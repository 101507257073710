import { Lesson, LessonResponse } from "@aten/common/dist/models/Lesson";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { lessonsUrlApi } from "../config/api";
import { RootState } from "store";

export const lessonsApi = createApi({
  reducerPath: "lessonsApi",
  tagTypes: ["Lesson"],
  baseQuery: fetchBaseQuery({
    baseUrl: lessonsUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getLessons: build.query<LessonResponse, string>({
      query: () => "",
      providesTags: ["Lesson"],
    }),
    getLessonById: build.query<Lesson, string>({
      query: (id: string) => `/${id}`,
      transformResponse: (res: Lesson) => res,
      providesTags: ["Lesson"],
    }),
    getLessonByThemeId: build.query<LessonResponse, string>({
      query: (id: string) => `?themeId=${id}`,
      providesTags: ["Lesson"],
      transformResponse: (baseQueryReturnValue: LessonResponse)=> {
        const {objects,...rest} = baseQueryReturnValue
        return {...rest, objects: objects.map(e=>({...e, type: 'lesson'}))}
      },
    }),
    getLessonByThemeIdFormat: build.query<Lesson, string>({
      query: (id: string) => `/${id}`,
      transformResponse: (res: LessonResponse) => res.objects[0],
      providesTags: ["Lesson"],
    }),
    editLesson: build.mutation<string, Lesson>({
      query: (data: Lesson) => ({
        url: `/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Lesson"],
    }),
    setLesson: build.mutation<string, Lesson>({
      query: (data: Lesson) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Lesson"],
    }),
    deleteLesson: build.mutation<LessonResponse, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lesson"],
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useGetLessonByThemeIdQuery,
  useGetLessonByIdQuery,
  useEditLessonMutation,
  useSetLessonMutation,
  useDeleteLessonMutation,
  useGetLessonByThemeIdFormatQuery,
} = lessonsApi;
