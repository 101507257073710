import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { membersUrlApi } from "../config/api";
import { Members, MembersResponse } from "@aten/common/dist/models/Members";
import { RootState } from "store";

export const membersApi = createApi({
  reducerPath: "membersApi",
  tagTypes: ["Members"],
  baseQuery: fetchBaseQuery({
    baseUrl: membersUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getMembers: build.query<MembersResponse, void>({
      query: () => ({
        url: "",
      }),
      providesTags: ["Members"],
    }),
  }),
});

export const { useGetMembersQuery } = membersApi;
