import { Project, ProjectResponse } from "@aten/common/dist/models/Project";
import { User } from "@aten/common/dist/models/User";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { IPagination } from "hooks/usePagination";
import { userApi } from "services/userService";

import { RootState } from "store";
import { projectsUrlApi } from "../config/api";

export const projectsApi = createApi({
    reducerPath: "projectsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: projectsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["Project"],
    endpoints: (builder) => ({
        getProjects: builder.query<
            ProjectResponse,
            {
                pagination?: IPagination;
                userId?: number;
                StatusId?: number;
                ProjectName?: string;
            }
        >({
            query: ({ pagination, userId, StatusId, ProjectName }) => {
                return {
                    url: ``,
                    params: {
                        ...pagination,
                        userId,
                        StatusId,
                        ProjectName,
                    },
                };
            },
            providesTags: ["Project"],
        }),
        getProjectById: builder.query<Project | undefined, string>({
            query: (id) => ({
                url: "",
                params: {
                    Id: id,
                },
            }),
            providesTags: ["Project"],
            transformResponse: (res: ProjectResponse) => {
                if (res.objects.length === 0) {
                    return undefined;
                }
                return res.objects[0];
            },
        }),
        DeleteProject: builder.mutation({
            query: (id: string) => ({
                url: id,
                method: "DELETE",
            }),
            invalidatesTags: ["Project"],
        }),
        EditProject: builder.mutation({
            query: (data) => ({
                url: `${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Project"],
        }),
        AddProject: builder.mutation({
            query: (data) => ({
                url: "",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Project"],
        }),
    }),
});

const projectsExtendedApi = projectsApi.injectEndpoints({
    endpoints: (build) => ({
        getProjectByIdWithDistAndIntgr: build.query<
            { project?: Project; distributor?: User[]; integrators?: User[] },
            string
        >({
            async queryFn(id, { dispatch }) {
                const project = id
                    ? await dispatch(projectsApi.endpoints.getProjectById.initiate(id, { forceRefetch: true })).unwrap()
                    : undefined;
                const integrators = await dispatch(
                    userApi.endpoints.getUsersByRole.initiate((0).toString() ?? "")
                ).unwrap();
                const distributor = await dispatch(
                    userApi.endpoints.getUsersByRole.initiate((1).toString() ?? "")
                ).unwrap();

                return { data: { project, distributor, integrators } };
            },
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useDeleteProjectMutation,
    useGetProjectByIdWithDistAndIntgrQuery,
    useEditProjectMutation,
    useAddProjectMutation,
} = projectsExtendedApi;
