import { CourseResponse } from "@aten/common/dist/models/Course";
import { CoursesToUsers, CoursesToUsersResponse } from "@aten/common/dist/models/CoursesToUsers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "store";
import { coursesToUsersUrlApi } from "../config/api";
import { coursesApi } from "./coursesService";

interface ObjectQuery {
    userId?: string;
    courseId?: string;
}

interface CoursesInfo {
    userId: number;
    ObjectsCount?: number;
    StartIndex?: number;
    IsNeedStatus?: boolean;
}

export const coursesToUsersApi = createApi({
    reducerPath: "coursesToUsersApi",
    tagTypes: ["CoursesToUsers"],
    baseQuery: fetchBaseQuery({
        baseUrl: coursesToUsersUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getCoursesToUsersByCourseAndUserId: build.query<CoursesToUsers, ObjectQuery>({
            query: ({ userId, courseId }) => `?CourseId=${courseId}&UserId=${userId}`,
            providesTags: ["CoursesToUsers"],
            transformResponse: (res: CoursesToUsersResponse) => {
                return res.objects[0];
            },
        }),
        getCoursesToUsersByUserId: build.query<CoursesToUsersResponse, string>({
            query: (userId: string) => `?UserId=${userId}`,
            providesTags: ["CoursesToUsers"],
        }),
        setCoursesToUsers: build.mutation<string, CoursesToUsers>({
            query: (data: CoursesToUsers) => ({
                url: "",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["CoursesToUsers"],
        }),
        editCoursesToUsers: build.mutation<CourseResponse, CoursesToUsers>({
            query: (data: CoursesToUsers) => ({
                url: `${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["CoursesToUsers"],
        }),
    }),
});

export const coursesToUsersExtendedApi = coursesToUsersApi.injectEndpoints({
    endpoints: (build) => ({
        getCoursesByUserId: build.query<CourseResponse, CoursesInfo>({
            async queryFn(data, { dispatch }) {
                const coursesToUsers = await dispatch(
                    coursesToUsersApi.endpoints.getCoursesToUsersByUserId.initiate(data.userId.toString())
                )
                    .unwrap()
                    .then((result) => result.objects);
                const coursesIds = coursesToUsers.map((element) => element.course?.id);
                return {
                    data: await dispatch(
                        coursesApi.endpoints.getCoursesByIds.initiate({
                            ids: coursesIds.join() || '-1',
                        })
                    ).unwrap(),
                };
            },
            providesTags: ["CoursesToUsers"],
        }),
    }),
});

export const {
    useGetCoursesToUsersByCourseAndUserIdQuery,
    useGetCoursesByUserIdQuery,
    useSetCoursesToUsersMutation,
    useEditCoursesToUsersMutation,
} = coursesToUsersExtendedApi;
