import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionAnswer } from "@aten/common/models/QuestionAnswers";

const initialState: QuestionAnswer[] = [];
export const testAnswerSlice = createSlice({
    name: "attempt",
    initialState,
    reducers: {
        setTestAnswer: (state, action: PayloadAction<QuestionAnswer>) => {
            state.push(action.payload);
        },
        getTestAnswer: (state, action: PayloadAction<number>) => {
            return state;
        },
    },
});
export const { setTestAnswer, getTestAnswer } = testAnswerSlice.actions;
export default testAnswerSlice.reducer;
