import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { usersUrlApi } from "config/api";
import { UserLogin, UserLoginResponse } from "@aten/common/dist/models/User";
import { userApi } from "services/userService";
import { login } from "slices/authSlice";
import { RootState } from "store";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: usersUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<UserLoginResponse, UserLogin>({
      query: (userData) => ({
        url: "/Login",
        method: "POST",
        body: userData,
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        const {
          data: { token },
        } = await cacheDataLoaded;

        dispatch(userApi.endpoints.getUserByToken.initiate({ token }))
          .unwrap()
          .then((res) => {
            dispatch(login({ user: res, token }));
          })
          .catch((err) => {
            console.error(err);
          });
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
