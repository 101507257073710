import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { ConfigProvider } from "antd";
import rus from "antd/lib/locale/ru_RU";
import Loader from "components/Loader/Loader";
import moment from "moment";
import "moment/locale/ru";
import { Suspense } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persist } from "store";
import App from "./App";

moment.locale("ru");

ReactDOM.render(
    //   <React.StrictMode>
    <ConfigProvider locale={rus}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persist}>
                <Suspense fallback={<Loader />}>
                    <HashRouter>
                        <App />
                    </HashRouter>
                </Suspense>
            </PersistGate>
        </Provider>
    </ConfigProvider>,
    //   </React.StrictMode>,
    document.getElementById("root")
);
