import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    id: 0,
};
export const testAttemptSlice = createSlice({
    name: "attempt",
    initialState,
    reducers: {
        setAttemptId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        getAttemptId: (state, action: PayloadAction<number>) => {
            return state;
        },
    },
});
export const { setAttemptId, getAttemptId } = testAttemptSlice.actions;
export default testAttemptSlice.reducer;
