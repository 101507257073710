import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { advantagesApi } from "services/advantagesService";
import { authApi } from "services/authService";
import { CooperationApi } from "services/cooperationService";
import { coursesApi } from "services/coursesService";
import { coursesToUsersApi } from "services/coursesToUsersService";
import { engineersApi } from "services/engineersService";
import { engineersToUsersApi } from "services/engineersToUsersService";
import { eventContactsApi } from "services/eventContactsService";
import { eventRegistrationGuestApi } from "services/eventRegistrationGuestService";
import { eventRegistrationsApi } from "services/eventRegistrationsApi";
import { EventsApi } from "services/eventsServise";
import { filesApi } from "services/fileService";
import { lessonsApi } from "services/lessonService";
import { lessonsStagesApi } from "services/lessonStagesService";
import { licencesApi } from "services/licencesServices";
import { managersApi } from "services/managerService";
import { managersToUsersApi } from "services/managersToUsersService";
import { membersApi } from "services/membersService";
import { modelsAndProjectContollerApi } from "services/modelsAndProjectContollerService";
import { newsApi } from "services/newsServices";
import { phonesApi } from "services/phonesService";
import { priceListsApi } from "services/priceListsService";
import { projectsApi } from "services/projectsService";
import { projectsToManagersApi } from "services/projectToManagerServise";
import { questionAnswersApi } from "services/questionAnswersService";
import { questionApi } from "services/questionsServices";
import { testsApi } from "services/testsService";
import { testsStagesApi } from "services/testsStagesService";
import { themesApi } from "services/themeService";
import { UsersCoursesApi } from "services/usersCoursesService";
import { userApi } from "services/userService";
import authReducer from "slices/authSlice";
import coursesReducer, { coursesSlice } from "slices/coursesSlice";
import { testsResultApi } from "../services/testResults";
import { testAttemptSlice } from "../slices/testAttemptSlice";
import { testsAttemptApi } from "../services/testAttempt";
import { testResultSlice } from "../slices/testResultslice";
import { testAnswerSlice } from "../slices/testAnswersSilce";
import { timerSlice } from "../slices/timerSlice";
import { themesToUsersApi } from "../services/themesToUsersCourse";

const persistConfig = {
    key: "client",
    storage,
    blacklist: [
        themesToUsersApi.reducerPath,
        testsStagesApi.reducerPath,
        lessonsStagesApi.reducerPath,
        userApi.reducerPath,
        authApi.reducerPath,
        projectsApi.reducerPath,
        managersApi.reducerPath,
        engineersApi.reducerPath,
        themesApi.reducerPath,
        testsApi.reducerPath,
        testsAttemptApi.reducerPath,
        testsStagesApi.reducerPath,
        coursesApi.reducerPath,
        coursesToUsersApi.reducerPath,
        filesApi.reducerPath,
        phonesApi.reducerPath,
        eventContactsApi.reducerPath,
        EventsApi.reducerPath,
        lessonsApi.reducerPath,
        lessonsStagesApi.reducerPath,
        UsersCoursesApi.reducerPath,
        eventRegistrationsApi.reducerPath,
        questionApi.reducerPath,
        questionAnswersApi.reducerPath,
        newsApi.reducerPath,
        testsResultApi.reducerPath,
        membersApi.reducerPath,
        licencesApi.reducerPath,
        advantagesApi.reducerPath,
        managersToUsersApi.reducerPath,
        engineersToUsersApi.reducerPath,
        projectsToManagersApi.reducerPath,
        CooperationApi.reducerPath,
        eventRegistrationGuestApi.reducerPath,
        priceListsApi.reducerPath,
        modelsAndProjectContollerApi.reducerPath,
    ],
};

const rootReducer = combineReducers({
    auth: authReducer,
    courses: coursesReducer,
    testAttempt: testAttemptSlice.reducer,
    testResult: testResultSlice.reducer,
    testAnswer: testAnswerSlice.reducer,
    timer: timerSlice.reducer,
    [modelsAndProjectContollerApi.reducerPath]: modelsAndProjectContollerApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [managersApi.reducerPath]: managersApi.reducer,
    [engineersApi.reducerPath]: engineersApi.reducer,
    [themesApi.reducerPath]: themesApi.reducer,
    [themesToUsersApi.reducerPath]: themesToUsersApi.reducer,
    [testsApi.reducerPath]: testsApi.reducer,
    [testsAttemptApi.reducerPath]: testsAttemptApi.reducer,
    [testsStagesApi.reducerPath]: testsStagesApi.reducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    [coursesToUsersApi.reducerPath]: coursesToUsersApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [phonesApi.reducerPath]: phonesApi.reducer,
    [eventContactsApi.reducerPath]: eventContactsApi.reducer,
    [EventsApi.reducerPath]: EventsApi.reducer,
    [lessonsApi.reducerPath]: lessonsApi.reducer,
    [lessonsStagesApi.reducerPath]: lessonsStagesApi.reducer,
    [UsersCoursesApi.reducerPath]: UsersCoursesApi.reducer,
    [eventRegistrationsApi.reducerPath]: eventRegistrationsApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [questionAnswersApi.reducerPath]: questionAnswersApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [testsResultApi.reducerPath]: testsResultApi.reducer,
    [membersApi.reducerPath]: membersApi.reducer,
    [licencesApi.reducerPath]: licencesApi.reducer,
    [advantagesApi.reducerPath]: advantagesApi.reducer,
    [managersToUsersApi.reducerPath]: managersToUsersApi.reducer,
    [engineersToUsersApi.reducerPath]: engineersToUsersApi.reducer,
    [projectsToManagersApi.reducerPath]: projectsToManagersApi.reducer,
    [CooperationApi.reducerPath]: CooperationApi.reducer,
    [eventRegistrationGuestApi.reducerPath]: eventRegistrationGuestApi.reducer,
    [priceListsApi.reducerPath]: priceListsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            modelsAndProjectContollerApi.middleware,
            advantagesApi.middleware,
            userApi.middleware,
            authApi.middleware,
            projectsApi.middleware,
            managersApi.middleware,
            engineersApi.middleware,
            themesApi.middleware,
            themesToUsersApi.middleware,
            testsApi.middleware,
            testsAttemptApi.middleware,
            testsStagesApi.middleware,
            coursesApi.middleware,
            coursesToUsersApi.middleware,
            filesApi.middleware,
            EventsApi.middleware,
            eventContactsApi.middleware,
            lessonsApi.middleware,
            lessonsStagesApi.middleware,
            UsersCoursesApi.middleware,
            eventRegistrationsApi.middleware,
            phonesApi.middleware,
            questionApi.middleware,
            questionAnswersApi.middleware,
            newsApi.middleware,
            testsResultApi.middleware,
            membersApi.middleware,
            licencesApi.middleware,
            CooperationApi.middleware,
            managersToUsersApi.middleware,
            engineersToUsersApi.middleware,
            projectsToManagersApi.middleware,
            priceListsApi.middleware,
        ]),
});

export const persist = persistStore(store);
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
