import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { engineersUrlApi } from "../config/api";
import { Engineers, EngineersResponse, NewEngineer } from "@aten/common/dist/models/Engineers";

import { RootState } from "store";

export const engineersApi = createApi({
  reducerPath: "engineersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: engineersUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Engineers"],
  endpoints: (builder) => ({
    getEngineers: builder.query<EngineersResponse, number>({
      query: (ObjectsCount: number) => {
        return {
          url: "",
          params: {
            ObjectsCount,
          },
        };
      },
    }),
    getEngineerById: builder.query<Engineers, string>({
      query: (id) => ({
        url: "",
        params: {
          Id: id,
        },
      }),
      transformResponse: (res: Engineers[]) => {
        return res[0];
      },
    }),
    setEngineer: builder.mutation<{addedId: number}, NewEngineer>({
      query: (props: NewEngineer) => ({
        url: "",
        method: "POST",
        body: props,
      }),
    }),
  }),
});

export const { useGetEngineersQuery, useGetEngineerByIdQuery, useSetEngineerMutation } = engineersApi;
