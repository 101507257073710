import {UrlApi} from "@aten/admin/src/config/api";
import {Manager} from "@aten/common/dist/models/Manager";
import {ProjectToManagersResponse} from "@aten/common/dist/models/projectsToManagers";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {managersToUsersUrlApi} from "config/api";
import {managersApi} from "services/managerService";
import {RootState} from "store";

export const managersToUsersApi = createApi({
    reducerPath: "managersToUsersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: managersToUsersUrlApi,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: build => ({
        getManagersToUsers: build.query<ProjectToManagersResponse, string>({
            query: (UserId: string) => ({
                url: '',
                params: {
                    UserId
                }
            })
        }),
        setManagerToUser: build.mutation<void, { managerId: number, userId: number }>({
            query: (body) => ({
                url: '',
                method: "POST",
                body
            })
        })
    })
})


export const managersToUsersExtendedApi = managersToUsersApi.injectEndpoints({
    endpoints: build => ({
        getManagersToUsersWithData: build.query<Manager[], string>({
            async queryFn(id, {dispatch}) {
                const managersList = await dispatch(managersToUsersApi.endpoints.getManagersToUsers.initiate(id, {forceRefetch: true})).unwrap()

                const managersDataList = await Promise.all(
                    managersList.objects
                        .map(({managerId}) =>
                            dispatch(managersApi.endpoints.getManagerById.initiate(managerId.toString(), {forceRefetch: true})).unwrap() ?? []
                        )
                )

                return {data: managersDataList}
            }
        })
    })
})


export const {useGetManagersToUsersQuery, useGetManagersToUsersWithDataQuery, useSetManagerToUserMutation} = managersToUsersExtendedApi