import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { newsUrlApi } from "../config/api";
import { News, NewsResponse } from "@aten/common/dist/models/News";
import { RootState } from "store";
import { IPagination } from "hooks/usePagination";

export const newsApi = createApi({
    reducerPath: "newsApi",
    tagTypes: ["News"],
    baseQuery: fetchBaseQuery({
        baseUrl: newsUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        getNews: build.query<NewsResponse, { pagination: IPagination }>({
            query: ({ pagination }) => ({
                url: "",
                params: {
                    ...pagination,
                },
            }),
            providesTags: ["News"],
        }),
        getNewsById: build.query<News, string>({
            query: (id: string) => `/${id}`,            
            providesTags: ["News"],
        }),
        editNews: build.mutation<string, News>({
            query: (body: News) => ({
                url: `${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["News"],
        }),
        setNews: build.mutation<string, News>({
            query: (body: News) => ({
                url: "",
                method: "POST",
                body,
            }),
            invalidatesTags: ["News"],
        }),
        deleteNews: build.mutation<NewsResponse, string>({
            query: (id: string) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["News"],
        }),
    }),
});

export const { useGetNewsQuery, useGetNewsByIdQuery, useEditNewsMutation, useSetNewsMutation, useDeleteNewsMutation } =
    newsApi;
