import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { modelsAndProjectContollerUrlApi, roleToBudgetUrlApi } from "config/api";
import { RootState } from "store";
import { ModelsAndProjectTypeResponse } from "@aten/common/models/ModelsAndProject";
import { Model, NewModel } from "@aten/common/models/Model";

export const modelsAndProjectContollerApi = createApi({
    reducerPath: "modelsAndProjectContollerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: modelsAndProjectContollerUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    tagTypes: ["Models"],
    endpoints: (build) => ({
        getModelsByProjectId: build.query<ModelsAndProjectTypeResponse | undefined, string>({
            query: (ProjectId: string) => {
                return {
                    url: "",
                    params: {
                        ProjectId,
                    },
                };
            },
        }),
        add: build.mutation<NewModel, NewModel>({
            query: (model) => {
                return {
                    url: "",
                    method: "POST",
                    body: model,
                };
            },
        }),
        edit: build.mutation<void, Model>({
            query: (model) => {
                return {
                    url: `/${model.id}`,
                    method: "PUT",
                    body: model,
                };
            },
        }),
        delete: build.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/${id}`,
                    method: "DELETE",
                };
            },
        }),
    }),
});

export const ModelsAndProjectContollerExtendedApi = modelsAndProjectContollerApi.injectEndpoints({
    endpoints: (build) => ({
        addModelToProject: build.mutation<{}, NewModel[]>({
            async queryFn(models, { dispatch }) {
                const response = await Promise.all(
                    models.map((el) =>
                        dispatch(modelsAndProjectContollerApi.endpoints.add.initiate(el))
                            .unwrap()
                            .then((res: any) => res)
                            .catch((er) => er)
                    )
                );
                const responseWithErrors = response.reduce<NewModel[]>((acc, el) => {
                    if (el.status === 409) {
                        return [...acc, el.data.model];
                    }
                    return acc;
                }, []);
                return {
                    data: {
                        responseWithErrors,
                    },
                };
            },
        }),
        editModelInProject: build.mutation<{}, Model[]>({
            async queryFn(models, { dispatch }) {
                await Promise.all(
                    models.map((el) => dispatch(modelsAndProjectContollerApi.endpoints.edit.initiate(el)).unwrap())
                );

                return {
                    data: {},
                };
            },
        }),
        deleteModelFromProject: build.mutation<{}, number[]>({
            async queryFn(modelIds, { dispatch }) {
                await Promise.all(
                    modelIds.map((el) => dispatch(modelsAndProjectContollerApi.endpoints.delete.initiate(el)).unwrap())
                );

                return {
                    data: {},
                };
            },
        }),
    }),
});
export const {
    useGetModelsByProjectIdQuery,
    useAddModelToProjectMutation,
    useEditModelInProjectMutation,
    useDeleteModelFromProjectMutation,
} = ModelsAndProjectContollerExtendedApi;
