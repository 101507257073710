import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { phonesUrlApi } from "../config/api";
import { Phone } from "@aten/common/dist/models/Phones";
import { RootState } from "store";
export const phonesApi = createApi({
  reducerPath: "phoneApi",
  baseQuery: fetchBaseQuery({
    baseUrl: phonesUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Phones"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    setPhone: build.mutation<string, Phone>({
      query: (data: Phone) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Phones"],
    }),
    editPhone: build.mutation<string, Phone>({
      query: (data: Phone) => ({
        url: `/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Phones"],
    }),
    deletePhone: build.mutation<string, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Phones"],
    }),
  }),
});

export const { useSetPhoneMutation, useEditPhoneMutation, useDeletePhoneMutation } = phonesApi;
