import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { testAtemptUrlApi } from "../config/api";
import { RootState } from "store";
import { AttemptQuestion } from "@aten/common/models/TestResult";
import { IQuestionPayload, QuestionAnswer } from "@aten/common/models/QuestionAnswers";

export const testsAttemptApi = createApi({
    reducerPath: "testsAttemptApi",
    tagTypes: ["TestsAttempt"],
    baseQuery: fetchBaseQuery({
        baseUrl: testAtemptUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        setAttemptQuestion: build.mutation<string, AttemptQuestion>({
            query: (data: AttemptQuestion) => ({
                url: ``,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TestsAttempt"],
        }),

        getAttemptQuestion: build.query<IQuestionPayload, string>({
            query: (testAttempt: any) => ({
                url: `?TestAttemptId=${testAttempt}`,
                method: "GET",
            }),
            providesTags: ["TestsAttempt"],

            transformResponse: (res: any) => {
                return res;
            },
        }),
    }),
});

export const { useSetAttemptQuestionMutation, useGetAttemptQuestionQuery } = testsAttemptApi;
