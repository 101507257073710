import "antd/dist/antd.min.css";
import { routesAuthorized } from "config/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useGetUserByTokenQuery } from "services/userService";
import { logout, setUser } from "slices/authSlice";
import "./assets/scss/style.scss";

const App = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuth, token, user } = useAppSelector(({ auth }) => auth);

  const { data: userData, isError } = useGetUserByTokenQuery(
    { token },
    {
      skip: !token || !!user,
    }
  );

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }
    if (!user && userData) {
      dispatch(setUser(userData));
    }
  }, [isAuth, location.pathname, userData, isError]);

  const routes = routesAuthorized;

  return (
    <Routes>
      {Object.values(routes).map((route) => {
        const Component = route.element;
        return <Route path={route.path} element={<Component />} key={route.path} />;
      })}
    </Routes>
  );
};

export default App;
