import {UrlApi} from "@aten/admin/src/config/api";
import {Cooperation} from "@aten/common/dist/models/Cooperation";
import {CooperationResponse} from "@aten/common/models/Cooperation";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {cooperationUrlApi} from "config/api";
import {RootState} from "store";

export const CooperationApi =
    createApi({
        reducerPath: "cooperationApi",
        baseQuery: fetchBaseQuery({
            baseUrl: cooperationUrlApi,
        }),
        refetchOnMountOrArgChange: true,
        endpoints: build => ({
            getCooperation: build.query<CooperationResponse, void>({
                query: () => ({
                    url: "",
                    params: {
                        startIndex: 0,
                        objectsCount: 1
                    }
                }),
            }),
        })
    })

export const {useGetCooperationQuery} = CooperationApi