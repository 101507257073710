import { ProjectsToManagers } from "@aten/common/models/projectsToManagers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { managersToProjectUrlApi } from "config/api";
import { RootState } from "store";

export const projectsToManagersApi = createApi({
    reducerPath: "projectsToManagersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: managersToProjectUrlApi,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set("Authorization", token);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        setManagerToProject: build.mutation<any, ProjectsToManagers>({
            query: ({ managerId, projectId }) => ({
                url: "",
                method: "POST",
                body: {
                    managerId,
                    projectId,
                },
            }),
        }),
        deleteManagerToProject: build.mutation<void, number | undefined>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useSetManagerToProjectMutation, useDeleteManagerToProjectMutation } = projectsToManagersApi;
