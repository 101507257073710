import { userCoursesUrlApi } from "../config/api";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "store";
import { Course, CourseResponse } from "@aten/common/dist/models/Course";
export const UsersCoursesApi = createApi({
  reducerPath: "usersCoursesApi",
  tagTypes: ["usersCourses"],
  baseQuery: fetchBaseQuery({
    baseUrl: userCoursesUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUsersCoures: build.query<CourseResponse, string>({
      query: (userId: string) => `/${userId}`,
      transformResponse: (res: CourseResponse) => {
        let transformedResponse: CourseResponse = {
          objects: res.objects.map((item: any) => item.course),
          total: res.total,
          requestedObjectsCount: res.requestedObjectsCount,
          requestedStartIndex: res.requestedStartIndex,
        };
        return transformedResponse;
      },
      providesTags: ["usersCourses"],
    }),
  }),
});

export const { useGetUsersCouresQuery } = UsersCoursesApi;
