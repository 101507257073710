import { RootState } from "store";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { eventContactsUrlApi } from "../config/api";
import { EventContact } from "@aten/common/dist/models/EventContact";

export const eventContactsApi = createApi({
  reducerPath: "eventContactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: eventContactsUrlApi,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["EventContacts"],
  endpoints: (build) => ({
    setEventContact: build.mutation<string, EventContact>({
      query: (data) => ({
        url: ``,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["EventContacts"],
    }),
    editEventContact: build.mutation<string, EventContact>({
      query: (data) => ({
        url: `/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EventContacts"],
    }),
    deleteEventContact: build.mutation<string, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventContacts"],
    }),
  }),
});

export const { useSetEventContactMutation, useEditEventContactMutation, useDeleteEventContactMutation } =
  eventContactsApi;
